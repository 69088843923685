import React from "react"
import { graphql } from "gatsby"

import { ContactSidebar, Layout, PageSection, SEO } from "../../components"

export default ({ data }) => {
  const { siteMetadata } = data.site

  return (
    <Layout>
      <>
        <SEO title="Contact" />
        <PageSection id="contact-content" theme="light" variant="left">
          <div className="u-clearfix  page-section--contact">
            <h2 className="page-section__title">
              Thanks!
              <br />
              I’ll get back to you as soon as I can.
            </h2>

            <ContactSidebar
              links={siteMetadata.links}
              contact={siteMetadata.contact}
            />
          </div>
        </PageSection>
      </>
    </Layout>
  )
}

export const ContactSuccessPageQuery = graphql`
  query ContactSuccessPageQuery {
    site {
      siteMetadata {
        links {
          email {
            label
            url
            address
          }
        }
        contact {
          social {
            label
            url
          }
        }
      }
    }
  }
`
